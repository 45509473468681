import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="お問い合わせありがとうございました。"
      pagedesc="お問い合わせありがとうございました。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-thanks">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">お問い合わせ</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg--01">contact</h3>
          <h4 className="head__desc--01">お問い合わせ</h4>
        </div>

        <div className="p-thanks thanks l-wrap">
          <h3 className="thanks__hdg">お問い合わせありがとうございました。</h3>
          <p className="thanks__txt">数日内に、ご連絡差し上げます。<br/>今しばらくお待ち下さい。</p>
        </div>
      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "reasons_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`